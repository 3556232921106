import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./TermOUBody.scss";

const TermOUBody = (props) => {
  return (
    <div className="article">
      <Row>
        <Col>
          <div className="terms-content cont">
            <p>
              Your use of Wunderbar Bologna website (located at
              https://wunderbarbologna.com/) on which these terms reside, and
              the features at this site (collectively, the “Site”) are subject
              to these Terms of Use. Please read these Terms of Use carefully
              before using this Site. The Site is owned or controlled Tyson
              Foods Inc. (“Tyson Foods”). By accessing this Site you agree to
              and are bound by these terms (the “Terms”).
            </p>
            <p>
              From time to time we may update this Site and these Terms. Your
              use of this Site after we post any changes to these Terms
              constitutes your agreement to those changes. You agree to review
              these Terms periodically to ensure that you are familiar with the
              most recent version.
              <br />
              Tyson Foods Content
            </p>
            <p>
              Content on this Site that is provided by Tyson Foods or its
              licensors, including certain graphics, photographs, images, screen
              shots, text, digitally downloadable files, trademarks, logos,
              product and program names, slogans, and the compilation of the
              foregoing (“Tyson Foods Content”) is the property of Tyson Foods
              and its licensors, and is protected in the U.S. and
              internationally under trademark, copyright, and other intellectual
              property laws.
            </p>
            <p>
              You agree not to download, display or use any Tyson Foods Content
              located on the Site in connection with products or services that
              are not those of Tyson Foods, in any other manner that is likely
              to cause confusion among consumers, that dilutes the strength of
              Tyson Foods or its licensor’s property, or that otherwise
              infringes Tyson Foods’ or its licensors’ intellectual property
              rights. You further agree to in no other way misuse any Tyson
              Foods Content or Third Party Content that appears on this Site.
            </p>
            <p>
              If you are a trademark or copyright owner and you believe that
              your trademark or copyright rights have been violated, please go
              to our Takedown Policy and follow the relevant instructions.
              <a
                href="https://WWW.TYSONFOODS.COM/INTELLECTUAL-PROPERTY-INFRINGEMENT-NOTIFICATION"
                target="_blank"
                rel="noopener"
              >
                &nbsp;HTTPS://WWW.TYSONFOODS.COM/INTELLECTUAL-PROPERTY-INFRINGEMENT-NOTIFICATION
              </a>
            </p>
            <h3>Use of the Site/User Content</h3>
            <p>
              The following requirements apply to your use of the Site: (a) you
              will not use any electronic communication feature of the Site for
              any purpose that is unlawful, tortious, intrusive on another’s
              privacy, harassing, libelous, defamatory, obscene, or threatening;
              (b) you will not upload, post, reproduce, or distribute any
              content, information, software, or other material that infringes
              on the intellectual property rights or other rights of any third
              party; and (c) you will not use the Site for any commercial
              purpose not expressly approved by Tyson Foods in writing.
            </p>
            <p>
              On certain areas of our Site you may be able to submit written
              posts and certain other materials (“User Content”). By using these
              features, you agree that you will not post any content that is
              unlawful, harmful, tortious, defamatory, libelous, obscene,
              invasive of the privacy of another person, threatening, harassing,
              infringing, pornographic, or violent; that you will not post any
              content that infringes or violates the rights of any third party;
              that you will not impersonate any person or organization, or
              misrepresent an affiliation with another person or organization;
              and you will not post any content that contains viruses, corrupted
              files, or any other similar software or programs that may
              adversely affect the operation of the Site, or features of the
              Site.
            </p>
            <p>
              By displaying, publishing, or otherwise posting any User Content
              on or through the Site, you hereby grant to Tyson Foods a
              non-exclusive, sub-licensable, worldwide, fully-paid, royalty free
              license to use, modify, publicly perform, publicly display,
              reproduce, and distribute such User Content in any and all media
              now known or hereinafter developed, to the extent that such User
              Content is not deemed a work made for hire under the terms of your
              employment arrangement with Tyson Foods. This license includes the
              right to host, index, cache, distribute, and tag any User Content.
              You represent and warrant that you own the content submitted,
              displayed, published or posted by you on the Site and otherwise
              have the right to grant the license set forth herein.
            </p>
            <h3>Account/Site Termination</h3>
            <p>
              You understand and agree that you have no ownership rights to the
              Site or the materials and features therein. Tyson Foods may cancel
              your account and delete all User Content associated with your
              account at any time, and without notice, if Tyson Foods deems that
              you have violated these Terms or the law. Tyson Foods assumes no
              liability for any information removed from our Site, and reserves
              the right to permanently restrict access to the Site or a user
              account.
            </p>
            <h3>Indemnification</h3>
            <p>
              You agree to indemnify and hold Tyson Foods, its affiliates,
              parents, subsidiaries, officers, employees, and website
              contractors and each of their officers, employees and agents
              harmless from any claims, damages and expenses, including
              reasonable attorneys’ fees and costs, related to your violation of
              these Terms, or any violations of these Terms by your dependents
              or which arises from the use of User Content you submitted,
              posted, or otherwise provided to Tyson Foods or this Site.
            </p>
            <h3>Representations and Limitations of Liability</h3>
            <p>
              Tyson Foods makes no representations about the reliability of the
              features of this Site, the Content, User Content, or any other
              Site feature, and disclaims all liability in the event of any
              service failure. You acknowledge that any reliance on such
              material or systems will be at your own risk. Tyson Foods makes no
              representations regarding the amount of time that any Content or
              User Content will be preserved.
            </p>
            <p>
              Tyson Foods does not endorse, verify, evaluate or guarantee any
              information provided by users and nothing shall be considered as
              an endorsement, verification or guarantee of any User Content. You
              shall not create or distribute information, including but not
              limited to advertisements, press releases or other marketing
              materials, or include links to any sites which contain or suggest
              an endorsement by Tyson Foods without the prior review and written
              approval of Tyson Foods.
            </p>
            <p>
              THIS SITE IS PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS. NO
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THOSE
              OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ARE MADE
              WITH RESPECT TO THIS SITE OR ANY INFORMATION OR SOFTWARE THEREIN.
              UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL TYSON FOODS BE
              LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
              CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF OR INABILITY TO
              USE THIS SITE, NOR SHALL TYSON FOODS BE RESPONSIBLE FOR ANY
              DAMAGES WHATSOEVER THAT RESULT FROM MISTAKES, OMISSIONS,
              INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN
              OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE WHETHER
              OR NOT CAUSED BY EVENTS BEYOND TYSON FOODS’ REASONABLE CONTROL,
              INCLUDING BUT NOT LIMITED TO ACTS OF GOD, COMMUNICATIONS LINE
              FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS TO THIS SITE’S
              RECORDS, PROGRAMS, OR SERVICES. UNDER NO CIRCUMSTANCES, INCLUDING
              BUT NOT LIMITED TO A NEGLIGENT ACT, WILL TYSON FOODS OR ITS
              AFFILIATES OR AGENTS BE LIABLE FOR ANY DAMAGE OF ANY KIND THAT
              RESULTS FROM THE USE OF, OR THE INABILITY TO USE, THE SITE, EVEN
              IF TYSON FOODS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
              EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES; AS
              A RESULT, THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
            </p>
            <h3>Third Party Websites</h3>
            <p>
              This Site may hyperlink to sites not maintained by or related to
              Tyson Foods. Hyperlinks are provided as a service to users and are
              not sponsored by or affiliated with this Site or Tyson Foods, and
              Tyson Foods makes no representations or warranties about the
              content, completeness, or accuracy of those third party sites.
              Information you submit at a third party site accessible from this
              Site is subject to the terms of that site’s privacy policy, and
              Tyson Foods has no control over how your information is collected,
              used, or otherwise handled.
            </p>
            <h3>Miscellaneous</h3>
            <p>
              Both you and Tyson Foods acknowledge and agree that no partnership
              is formed and neither of you nor Tyson Foods has the power or the
              authority to obligate or bind the other.
            </p>
            <p>
              These Terms are governed by the laws of the state of Arkansas, in
              the United States of America, and are subject to all applicable
              federal, state and local laws and regulations. All issues and
              questions concerning the construction, validity, interpretation
              and enforceability of these Terms shall be governed by, and
              construed in accordance with, the laws of Arkansas, without giving
              effect to the conflict of laws rules thereof, and any matters or
              proceedings which are not subject to arbitration as set forth
              herein and/or for entering any judgment on an arbitration award,
              shall take place in the State of Arkansas. By using this site, you
              hereby agree that any and all disputes regarding these Terms will
              be subject to the courts located in Arkansas. These Terms operate
              to the fullest extent permissible by law. YOU AGREE THAT, TO THE
              EXTENT PERMITTED BY APPLICABLE LAW, ANY AND ALL DISPUTES, CLAIMS
              AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE WEB
              SITE, THESE TERMS, AND/OR THE SUBMISSION OF AND/OR OUR USE OR
              DISTRIBUTION OF CONTENT, WILL BE RESOLVED INDIVIDUALLY, WITHOUT
              RESORT TO ANY FORM OF CLASS ACTION.
            </p>
            <p>
              Any controversy or claim arising out of or relating to this
              Agreement shall be settled by binding arbitration in a location
              determined by the arbitrator as set forth herein (provided that
              such location is reasonably convenient for claimant), or at such
              other location as may be mutually agreed upon by the parties, in
              accordance with the procedural rules for commercial disputes set
              forth in the Comprehensive Arbitration Rules and Procedures of
              JAMS (“JAMS Rules and Procedures”) then prevailing, and judgment
              upon the award rendered by the arbitrator(s) may be entered in any
              court having jurisdiction thereof. The arbitrator shall be
              selected pursuant to the JAMS Rules and Procedures. The arbitrator
              shall apply Arkansas law consistent with the Federal Arbitration
              Act and applicable statutes of limitations, and shall honor claims
              of privilege recognized at law. In the event that the claimant is
              able to demonstrate that the costs of arbitration will be
              prohibitive as compared to the costs of litigation, Tyson Foods
              will pay as much of the claimant’s filing and hearing fees in
              connection with the arbitration as the arbitrator deems necessary
              to prevent the arbitration from being cost-prohibitive. If any
              part of this arbitration provision is deemed to be invalid,
              unenforceable or illegal (other than that claims will not be
              arbitrated on a class or representative basis), or otherwise
              conflicts with the rules and procedures established by JAMS, then
              the balance of this arbitration provision shall remain in effect
              and shall be construed in accordance with its terms as if the
              invalid, unenforceable, illegal or conflicting provision were not
              contained herein. If, however, the portion that is deemed invalid,
              unenforceable or illegal is that claims will not be arbitrated on
              a class or representative basis, then the entirety of this
              arbitration provision shall be null and void, and neither claimant
              nor Tyson Foods shall be entitled to arbitrate their dispute. Upon
              filing a demand for arbitration, all parties to such arbitration
              shall have the right of discovery, which discovery shall be
              completed within sixty days after the demand for arbitration is
              made, unless further extended by mutual agreement of the parties.
              THE ARBITRATION OF DISPUTES PURSUANT TO THIS AGREEMENT SHALL BE IN
              YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
              IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. THE
              ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS
              OR PARTIES WHO MAY BE SIMILARLY SITUATED. YOU AGREE THAT TO THE
              EXTENT PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES,
              CLAIMS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE
              SITES AND/OR THIS AGREEMENT, WILL BE RESOLVED INDIVIDUALLY THROUGH
              BINDING ARBITRATION AS SET FORTH ABOVE, WITHOUT RESORT TO ANY FORM
              OF CLASS ACTION; (2) ANY AND ALL CLAIMS, JUDGMENTS AND AWARDS WILL
              BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED (IF
              ANY), BUT IN NO EVENT WILL ATTORNEYS’ FEES BE AWARDED OR
              RECOVERABLE; (3) UNDER NO CIRCUMSTANCES WILL YOU BE PERMITTED TO
              OBTAIN ANY AWARD FOR, AND YOU HEREBY KNOWINGLY AND EXPRESSLY WAIVE
              ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL, CONSEQUENTIAL OR SPECIAL
              DAMAGES, LOST PROFITS AND/OR ANY OTHER DAMAGES, OTHER THAN ACTUAL
              OUT OF POCKET EXPENSES, AND/OR ANY RIGHTS TO HAVE DAMAGES
              MULTIPLIED OR OTHERWISE INCREASED; AND (4) YOUR REMEDIES ARE
              LIMITED TO A CLAIM FOR MONEY DAMAGES (IF ANY) AND YOU IRREVOCABLY
              WAIVE ANY RIGHT TO SEEK INJUNCTIVE OR EQUITABLE RELIEF. SOME
              JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
              LIABILITY, SO THE ABOVE MAY NOT APPLY TO YOU.
            </p>
            <p>
              On certain areas of our Site, you may be given the ability to
              provide us with personally identifiable information. Please read
              our&nbsp;PRIVACY POLICY&nbsp;for more information about our
              information collection and use practices, which Policy applies to
              information you submit on this Site, and you hereby agree to the
              terms of that Policy.
            </p>
            <p>
              If Tyson Foods fails to act with respect to your breach or anyone
              else’s breach on any occasion, Tyson Foods is not waiving its
              right to act with respect to future or similar breaches. If any
              provision of these Terms shall be unlawful, void or for any reason
              unenforceable, then that provision shall be deemed severable from
              these terms of use and shall not affect the validity and
              enforceability of any remaining provisions. These Terms constitute
              a binding agreement between you and Tyson Foods, and is accepted
              by you upon your use of the Site. These Terms constitute the
              entire agreement between you and Tyson Foods regarding the use of
              the Site. By using the Site you represent that you are capable of
              entering into a binding agreement, and that you agree to be bound
              by these Terms
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TermOUBody;
