import React, { useState } from "react";
import "./TermOUHeader.scss";

const TermOUHeader = (props) => {
  return (
    <div className="ro">
      <div className="ro-text">
        <h1>Terms of Use</h1>
      </div>
    </div>
  );
};

export default TermOUHeader;
