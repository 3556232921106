import React from "react";
import Layout from "../components/Layout";
import TermOUHeader from "../components/TermOUHeader/TermOUHeader";
import TermOUBody from "../components/TermOUBody/TermOUBody";

const About = () => {
  return (
    <Layout title="" description="">
      <TermOUHeader />
      <TermOUBody />
    </Layout>
  );
};

export default About;
